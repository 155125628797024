@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('./assets/fonts/narkisim/stylesheet.css');

html {
  overflow: hidden;
}

body {
  font-family: 'Vollkorn', serif, 'NarkisimMF', serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
  height: 100%;
  width: 100%;
}

html {
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
}

#root {
  width: 101%;
  height: 101%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-center-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}
