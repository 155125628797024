/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 21, 2022 */
$bengulat: 'benguiatnormal';


@font-face {
    font-family: 'benguiatnormal';
    src: url('benguiat_normal-webfont.woff2') format('woff2'),
         url('benguiat_normal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'benguiatregular';
    src: url('benguiat-webfont.woff2') format('woff2'),
         url('benguiat-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}