@import '../../assets/fonts/bengulat/stylesheet';

.about {
  height: 99vh;
  overflow-y: auto;
  width: 99vw;

  .about-container {
    display: flex;
    padding: 0 330px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .title-container {
      height: 580px;
      display: flex;
      align-items: end;
      padding: 0 220px;

      .title {
        height: 430px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;

        .title-first-letter {
          font-size: 290px;
          height: 209px;
          line-height: 225px;
          font-family: $bengulat;
        }

        .title-content {
          font-size: 38px;
          white-space: break-spaces;
        }
      }
    }

    .subtitle-container {
      display: flex;
      flex-direction: row;
      width: 700px;
      height: 410px;
      padding-left: 400px;
      align-items: start;
      gap: 3rem;

      .arrow {
        height: 28px;
        line-height: 35px;
      }

      .text {
        color: #aaaaaa;

        .subtitle {
          margin: 0;
          font-size: 27px;
          white-space: pre-wrap;
        }

        .subsubtitle {
          margin: 0;
          font-size: 19px;
        }
      }
    }

    .form-and-photo {
      position: relative;
      height: 1000px;
      width: 100%;

      .form {
        position: absolute;
        left: 40%;
        bottom: 5%;
        z-index: 2;
      }
      .photo {
        position: absolute;
        left: 5%;
        top: 10%;
        width: 700px;
        z-index: 1;
      }
    }

    .description {
      text-align: center;
      font-size: 1.4rem;
      color: #AEADAD;
    }

    .spacer {
      flex: 1;
    }
  }

  .about-container.smaller {
    padding: 0;

    .form-and-photo {
      width: 100%;

      .photo {
        left: 10%;
      }
    }
  }

  .form {
    transition: left .3s ease-in-out;
  }
}



.about.mobile {
  overflow-x: hidden;

  .top-padding {
    height: 100px;
  }

  .about-container {
    padding: 0;

    .title-container {
      padding: 0;
      height: 270px;

      .title {
        height: 270px;
        .title-first-letter {
          font-size: 170px;
          height: 120px;
          line-height: 30px;
        }

        .title-content {
          font-size: 23px;
          white-space: pre-wrap;
        }
      }
    }

    .subtitle-container {
      flex-direction: column;
      width: 80vw;
      padding-left: 0;
      height: 500px;
      .arrow {
      }

      .text {

        .subtitle {
          margin: 0;
          font-size: 20px;
          white-space: pre-wrap;
        }

        .subsubtitle {
          margin: 0;
          font-size: 14px;
        }
      }
    }

    .form-and-photo {
      position: relative;
      height: 400px;
      width: 100%;

      .form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
      .photo {
        display: none;
      }
    }

    .description {
      text-align: center;
      font-size: 1.4rem;
      color: #AEADAD;
    }

    .spacer {
      flex: 1;
    }
  }

  .about-container.smaller {
    max-width: 29rem;
    min-width: 300px;

    .title {
      padding: 0px 22px;

      .icon {
      }

      .content {
        font-size: 40px;
      }
    }

    .description {
      font-size: 1.1rem;
    }

    .spacer {
    }
  }

  .form {
    transition: left .3s ease-in-out;
  }
}