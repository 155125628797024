$vollkorn: 'vollkorn', serif;

.about-form-container {
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: black;
  filter: invert(1);

  div.inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    justify-content: space-between;
    gap: 2rem;

  .labels {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0.06em;

    .first-label {
      font-style: italic;
      font-weight: 600;
    }

    .second-label {
      font-weight: 400;
    }
  }

    input {
      height: 50px;
      font-size: 25px;
      padding-left: 12px;
      width: 95%;
      border-radius: 5px;
      border: 1px solid transparent;
      font-family: $vollkorn;
    }

    button.submit {
      border-radius: 300px;
      background-color: black;
      color: #fbfaf8;
      border-color: #fbfaf8;
      width: 160px;
      height: 70px;
      text-transform: none;
      font-size: 24px;
      $border-width: 2px;
      border-top-width: $border-width;
      border-right-width: $border-width;
      border-left-width: $border-width;
      border-bottom-width: $border-width;
      font-family: $vollkorn;
    }

    &.thank-you {
      padding: 0;
      label {
        font-size: 60px;
      }
    }
  }
}


.about-form-container.mobile {

  div.inner-container {
    padding: 1.5rem 1.5rem 0 1.5rem;

    .labels {
      font-size: 14px;
      gap: .3rem;

      .first-label {
      }

      .second-label {

      }
    }

    input {

    }

    button.submit {

    }

    &.thank-you {

      label {

      }
    }
  }
}