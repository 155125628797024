@import './src/utils/mixins/RotateText';

.text-border {
    border: 2px solid black;
    border-radius: 50%;
    position: absolute;
}
.rotated-char {
    text-align: center;
    font: 26px "Linux Libertine Mono O", serif;
    position: absolute;
    left: calc(50% - 13px);
    width: 26px;
    transform-origin: bottom center;
    vertical-align: center;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    span {
        text-align: center;
    }
}

.circle-text-wrapper {
    transform-origin: center;
    transition: transform .5s ease-in-out;
    position: absolute;
}

.wrapped-word {
    cursor: pointer;
    &:hover {
        color: black;
        & .rotated-char {
            font-weight: bolder;
        }
    }

    &.selected .rotated-char {
        font-weight: bold;
    }

}

.circle-drop-shadow {
    filter: drop-shadow(0 0 10px rgba(255,255,255,0.53));
    position: fixed;
    //left: 10%;
}


.CircleCrop {
    clip-path: circle(50.0% at 50% 50%);
    overflow: auto;
    padding: 0;
    transform: scale(1.01);
}

section {
    display: block;
}
