
.image-container {
  display: flex;
  place-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.mute {
  position: absolute;
  left: calc(50% - 2rem);
  bottom: calc(50% - 2rem);
  transform: scale(1.5);
  pointer-events: none;
  border-radius: 50%;
  background: #fbfaf8;
  width: 4rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.CircleCrop:hover .mute {
  opacity: .8;
}